<template>
  <div id="EditMessage">
    <div class="from" >
      <div v-if="editDetail.user">
        <span>头像</span>
        <img :src="editDetail.user.headimgurl?Global.changeImg(editDetail.user.headimgurl):'../../assets/image/home/image1.png'" />
      </div>
      <van-field
        v-model="name"
        type="text"
        name="姓名"
        label="姓名"
        placeholder="点击输入您的姓名"
      />
      <van-field
        v-model="phone"
        type="number"
        name="手机号"
        label="手机号"
        placeholder="点击输入您的手机号"
        :rules="[{ required: false, message: '点击输入您的手机号' }]"
      />

      <div>
        <span>性别</span>
        <span @click="show=true;sexShow = true" :class="{'gray':!sex}">{{sex}}</span>
      </div>
      
      <van-field
        v-model="company"
        type="text"
        name="所在公司"
        label="所在公司"
        placeholder="点击输入贵公司名字"
      />
      <div>
        <span>所在行业、职位</span>
        <span @click="show=true;indShow = true" :class="{'gray':!industry}">{{industry ? industry :'点击选择行业职位'}}{{job?job:''}}</span>
      </div>
      
      <van-field
        v-model="wechat"
        type="text"
        name="微信号"
        label="微信号"
        placeholder="点击输入您的微信号"
      />
      <div>
        <span>个人简介</span>
        <span @click="show=true;editShow = true" :class="{'gray':!intro}">{{intro ? intro :'介绍自己行业/职业背景'}}</span>
      </div>
       <van-field
        v-model="supply"
        type="text"
        name="我能提供的"
        label="我能提供的"
        placeholder="介绍下您的领域特长"
      />
      <van-field
        v-model="explore"
        type="text"
        name="想获得的"
        label="想获得的"
        placeholder="希望获得那些帮助"
      />
      <div>
        <span>收货地址</span>
        <span @click="show =true;addressShow = true" :class="{'gray':!city}">{{city ? city :'点击填写'}}</span>
      </div>
      <van-field
        v-model="address"
        type="text"
        name="详细地址"
        label="详细地址"
        placeholder="点击输入您的详细地址"
        :rules="[{ required: false, message: '点击输入您的详细地址' }]"
      />
      <div class="submit">
        <div @click="onSubmit">保存更改</div>
      </div>
    </div>
    
    
    <!-- 性别地址 -->
    <van-popup v-model="show" position="bottom"  >
      <van-picker v-if="sexShow"
        show-toolbar
        title="请选择"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
      <van-picker v-if="indShow"
        show-toolbar
        title="请选择"
        :columns="columnsTnd"
        @confirm="onConInd"
        @cancel="onCancel"
      />
      <div v-if="editShow" class="editSure">
        <van-field
          class="edit"
          v-model="intro"
          rows="2"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="介绍自己行业/职业背景（如：xxx职业，熟悉xxx领域等）"
          show-word-limit
        />
        <div >
          <span @click="editShow = false;show = false">确定</span>
        </div>
      </div>
      <van-area title="请选择" v-if="addressShow" :area-list="areaList" @confirm="onConAddress"
        @cancel="onCancel" />
    </van-popup>

    <van-popup v-model="showQrCode" style="background:none">
      <img class="main" src="img/kf3.png" />
      <div class="close">
        <span @click="showQrCode = false">
          <van-icon color="#fff" name="cross" style="font-size:0.2rem" />
        </span>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
export default {
  name: 'EditMessage',
   data(){
    return{
      areaList,
      show:false,
      showQrCode:false,
      sexShow:false,
      addressShow:false,
      indShow:false,
      editShow:false,
      phone:'',
      columns: ['男', '女'],
      columnsTnd: [
        // 第一列
        {
          values: ['自主创业', '金融保险','互联网', '观察者','文娱媒体'],
        },
        // 第二列
        {
          values: ['金融业', '销售', '理财经理/顾问','保险代理人/顾问','信贷业务','企业高端/CEO/创始人','风投/股权投资','基金经理','市场营销/品牌/公关','运营','IT支持/网络运维','技术/研发','培训/组训'],
        },
      ],
      message:'',
      editDetail:'',
      sex:'',
      sexNumber:'',
      company:'',//公司
      industry:'',//行业职位
      industryNumber:'',
      wechat:'',//微信号
      intro:'',//简介
      supply:'',//特长
      city:'',//城市
      address:'',//地址
      name:'',
      explore:'',
      job:'',
      user: ''
    }
  },
  mounted(){
    // 根据页面参数选择展示微信二维码
    if (this.$route.query.showQrCode == 1) {
      this.showQrCode = true
    }

    this.axios({
      method: 'GET',
      url: '/user/getMemberInfo',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        if(res.data.data){
          this.editDetail = res.data.data
          this.company = this.editDetail.company
          this.wechat = this.editDetail.wechat
          this.intro = this.editDetail.intro
          this.supply = this.editDetail.supply
          this.city = this.editDetail.city
          this.address = this.editDetail.address
          this.phone = this.editDetail.phone
          this.name = this.editDetail.name
          this.explore = this.editDetail.explore
          this.supply = this.editDetail.supply
          this.job = this.editDetail.job
          this.user = this.editDetail.user

          if(this.editDetail.user.sex == 0){
            this.sex = '请选择性别'
            this.sexNumber = 0
          }else if(this.editDetail.user.sex == 1){
            this.sex = '男'
            this.sexNumber = 1
          }else if(this.editDetail.user.sex == 2){
            this.sex = '女'
            this.sexNumber = 2
          }
          if(this.editDetail.industry == 1){
            this.industry = '自主创业'
            this.industryNumber = 1
          }else if(this.editDetail.industry == 2){
            this.industry = '金融保险'
            this.industryNumber = 2
          }else if(this.editDetail.industry == 3){
            this.industry = '互联网'
            this.industryNumber = 3
          }else if(this.editDetail.industry == 4){
            this.industry = '观察者'
            this.industryNumber = 4
          }else if(this.editDetail.industry == 5){
            this.industry = '文娱媒体'
            this.industryNumber = 5
          }
        }else{
          this.sex = '请选择性别'
          this.sexNumber = 0
        }
      }
    })
  },
  methods:{
     
    // 省市区
    onConAddress(value){
      this.show = false
      this.sexShow = false
      this.addressShow = false
      this.indShow = false
      this.editShow = false
      this.city = value[0].name+value[1].name+value[2].name
    },
    // 确定职业职位
    onConInd(value, index){
      this.show = false
      this.sexShow = false
      this.addressShow = false
      this.indShow = false
      this.editShow = false
      this.industry = value[0]
      this.industryNumber = index[0]+1
      this.job = value[1]
      console.log(this.industry,this.job)
    },
    // 确定性别
    onConfirm(value, index){
      this.show = false
      this.sexShow = false
      this.addressShow = false
      this.indShow = false
      this.editShow = false
      this.sex = value
      this.sexNumber = index+1
    },
    // 取消弹框
    onCancel(){
      if(this.show == false){
        this.show = false
        this.sexShow = false
        this.addressShow = false
        this.indShow = false
        this.editShow = false
      }
    },
    onSubmit(){
      if(!this.name){
        this.$toast('姓名不能为空')
        return
      }
      if(!this.phone){
        this.$toast('手机号不能为空')
        return
      }else if(!(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone))){ 
        this.$toast('手机号码有误')
        return false; 
      }   

      // if(!this.sex){
      //   this.$toast('性别不能为空')
      //   return
      // }
      // if(!this.company){
      //   this.$toast('公司不能为空')
      //   return
      // }
      // if(!this.industry){
      //   this.$toast('行业不能为空')
      //   return
      // }
      // if(!this.job){
      //   this.$toast('职位不能为空')
      //   return
      // }
      // if(!this.wechat){
      //   this.$toast('微信号不能为空')
      //   return
      // }
      // if(!this.supply){
      //   this.$toast('能提供不能为空')
      //   return
      // }
      // if(!this.explore){
      //   this.$toast('想获得的不能为空')
      //   return
      // }
      // if(!this.city){
      //   this.$toast('收货地址不能为空')
      //   return
      // }
      // if(!this.address){
      //   this.$toast('详细地址不能为空')
      //   return
      // }

      // 体验会员需要提醒完善所有信息
      if (
        (
          !this.sex || !this.company || !this.industry || !this.job || !this.wechat 
          || !this.supply || !this.explore || !this.city || !this.address
        ) && this.user.status == 8 && this.user.is_activate == 0
      ) {
        this.$dialog.confirm({
          title: '温馨提示',
          message: "完善所有信息即可激活会员资格\n是否继续填写？",
          confirmButtonText: '继续填写',
          cancelButtonText: '不，先提交',
          cancelButtonColor: '#ee0a24'
        }).catch(() => {
          this.submitData()
        });
      } else {
        this.submitData()
      }
    },
    submitData() {
      this.axios({
        method: 'PATCH',
        url: '/user/updateMemberInfo',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          name:this.name,
          company:this.company,
          industry:this.industryNumber,
          job:this.job,
          intro:this.intro,
          supply:this.supply,
          explore:this.explore,
          address:this.address,
          city:this.city,
          phone:this.phone,
          wechat:this.wechat,
          sex:this.sexNumber,
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast('保存成功')
          var _this = this;
          setTimeout(function(){
            _this.$router.push('/Personal')
          },2000)
        }else{
          for(var i in res.data.message){
            console.log(i)
            this.$toast(res.data.message[i][0])
            return
          }
        }
      })
    }
  },
  watch: {
    'show': 'onCancel'
  }
}
</script>
<style lang="less" scoped>
#EditMessage{
  padding-top: 0.15rem;
  .main{
    width: 2.63rem;
  }
  .close{
    text-align: center;
    >span{
      width: 0.35rem;
      height: 0.35rem;
      background: #6e6f72;
      border-radius: 50%;
      margin-top: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 0.2rem;
    }
  }
  .editSure{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #16171D;
    >div:last-child{
      text-align: right;
      padding: 0 0.15rem 0.11rem;
      >span{
        height: 0.3rem;
        line-height: 0.3rem;
        border-radius: 0.3rem;
        padding: 0 0.2rem;
        background: #3890FF;
        color: #F6F7FD;
        font-size: 0.13rem;
        display: inline-block;
      }
    }
  }
  .checkSex{
    width: 100%;
    >p{
      height: 0.42rem;
      line-height: 0.42rem;
      text-align: center;
      color: #989AB1;
      font-size: 0.14rem;
    }
  }
  >.from{
      overflow: auto;
      >div{
      display: flex;
      padding: 0.15rem;
      justify-content: space-between;
      border-bottom: .1px solid #5C5D6E61;
      align-items: center;
      font-size: 14px;
      >span:first-child{
        color: #ECEDF7;
      }
      >span:last-child{
        color: #ECEDF7;
      }
      >span.gray{
        color: #989AB1;
      }
      >img{
        width: 0.35rem;
        height: 0.35rem;
        border-radius: 50%;
      }
    }
  }
  >.from>.submit{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.35rem;
    margin-bottom: 0.35rem;
    border: none;
    div{
      width: 2.37rem;
      height: 0.4rem;
      line-height: 0.4rem;
      border-radius: 0.4rem;
      text-align: center;
      background: #3890FF;
      color: #F6F7FD;
    }
  }
}
</style>